nav ul {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
}
nav ul li {
  flex: 1;
  list-style: none;
}
nav ul li a {
  padding: 5px 10px;
  text-decoration: none;
  color: grey;
}

.timeline{
  display: flex;
  flex-direction: row;
  flex: 1;
}

.details {
  padding: 5px;
}
.details .leading {
  display: flex;
  flex-wrap: wrap;
}

.details .leading p{
  text-align: center;
  flex: 1;
}
.policy-update-span.replaced {
  background: grey;
}

.policy-config {
  flex: 1;
  color:  rgba(27, 27, 27);
}

.snapshot{
  flex: 1;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
}

.policy-update-span {
  flex: 1;
  border: 1px solid white;
  background: #347A61;
  color: white;
  border-radius: 5px;
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.25);
}
.policy-span {
  display: flex;
  padding-bottom: 10px;
}
p {
  padding: 0px;
  margin: 0;
}
pre {
 background-color: #EEEEEE;
  font-family: Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace,serif;
  margin-bottom: 10px;
  max-height: 600px;
  overflow: auto;
  padding: 5px;
  width: auto;
}
summary {
  cursor: pointer;
}
